<template>
  <div id="app">
    <el-container>
      <el-header style="text-align: center; font-size: 12px">
        <el-row>
          <!-- <el-col :span="1" :offset="1">
            <el-image style="width: 60px; height: 60px; vertical-align: middle; text-align: center;"
              :src="require('@/assets/logo.png')"></el-image>
          </el-col> -->
          <el-col :span="4" :offset="5" class="hidden-md-and-down">
            <div style="font-size: 24px; font-weight: bold; line-height: 50px;">申浩升法律咨询</div>
            <div style="font-size: 14px; line-height: 25px;">复杂而精细，是执行流程的特征</div>
            <div style="font-size: 14px; line-height: 25px;">严谨且细致，是我们的风格</div>
          </el-col>
          <el-col :xs="8" :sm="6" :md="4" :lg="2" :xl="2" class="tabs-title">
            <div class="grid-content bg-purple" @click="handleClick('home')">首页</div>
          </el-col>
          <el-col :xs="8" :sm="6" :md="4" :lg="2" :xl="2" class="tabs-title">
            <div class="grid-content bg-purple" @click="handleClick('service')">服务领域</div>
          </el-col>
          <el-col :xs="8" :sm="6" :md="4" :lg="2" :xl="2" class="tabs-title">
            <div class="grid-content bg-purple" @click="handleClick('knowlage')">项目简介</div>
          </el-col>
          <el-col :xs="8" :sm="6" :md="4" :lg="2" :xl="2" class="tabs-title">
            <div class="grid-content bg-purple" @click="handleClick('anli')">案例分享</div>
          </el-col>
          <el-col :xs="8" :sm="6" :md="4" :lg="2" :xl="2" class="tabs-title">
            <div class="grid-content bg-purple" @click="handleClick('about')">关于我们</div>
          </el-col>
          <!-- <el-col :span="1" class="tabs-title">
            <el-image style="width: 60px; height: 60px" :src="require('@/assets/qrcode-douyin.jpg')"></el-image>
          </el-col>
          <el-col :span="1">
            <el-image style="width: 60px; height: 60px" :src="require('@/assets/qrcode-wechat.jpg')"></el-image>
          </el-col> -->
        </el-row>
      </el-header>
      <el-divider></el-divider>
      <el-main>
        <el-tabs v-model="activeName">
          <el-tab-pane label="" name="home">
            <Home />
          </el-tab-pane>
          <el-tab-pane label="" name="service">
            <Service />
          </el-tab-pane>
          <el-tab-pane label="" name="anli">
            <Anli />
          </el-tab-pane>
          <el-tab-pane label="" name="knowlage">
            <Knowlage />
          </el-tab-pane>
          <el-tab-pane label="" name="about">
            <About />
          </el-tab-pane>
        </el-tabs>
      </el-main>

      <div class="float-button">
        <el-popover placement="top-start" title="扫一扫添加微信" width="200" trigger="hover" content="这是微信二维码。">
          <el-image
          style="display: block; width: 100%; "
          :src="require('@/assets/qrcode-wechat.jpg')" fit="cover" />

          <i class="el-icon-s-help" slot="reference"></i>
        </el-popover>
      </div>

      <el-footer style="text-align: center;">
        <p>Copyright @ 2024 - 至今 <a href="https://www.shenhaosheng.com" target="_blank">www.shenhaosheng.com</a>. All
          Rights Reserved <a href="https://beian.miit.gov.cn" target="_blank">.蜀ICP备2024096026号.</a></p>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import Home from './components/home/index'
import Service from './components/service/index'
import Anli from './components/anli/index'
import Knowlage from './components/knowlage/index'
import About from './components/about/index'

export default {
  name: 'App',
  components: {
    Home, Service, Anli, Knowlage, About
  },
  data() {
    return {
      // logo
      activeName: 'home'
    }
  },
  methods: {
    handleClick(page) {
      this.activeName = page
    }
  }
}
</script>

<style>
body {
  display: block;
  margin: 0px;
  /* background: radial-gradient(circle, #99e4ff 0%, #33ccff 100%); */
  background: white;
  letter-spacing: 2px;
  line-height: 30px;
}

.el-header {
  background-color: #ffffff;
  color: #333;
  line-height: 100px;

  /* display: flex;
  align-items: center;
  justify-content: center; */
}

@media only screen and (max-width: 767px) {
    .el-header {
        line-height: 30px;
    }
}

.el-header {
  height: 100px !important;
  padding: 0 0px !important;
}

.el-main {
  padding: 0px !important;
}

.el-tabs__nav-scroll {
  display: none;
}

.el-tabs__header {
  margin: 0px !important;
}

.tabs-title {
  font-size: 18px;
  font-weight: bold;
}

.el-divider--horizontal {
  margin: 2px 0 !important;
}

.float-button {
  position: fixed;
  /* 使按钮保持固定位置 */
  bottom: 40%;
  /* 距离底部20像素 */
  right: 20px;
  /* 距离右侧20像素 */
  background-color: #52a1f6;
  /* 背景颜色 */
  color: white;
  /* 文本颜色 */
  /* border: none; 无边框 */
  border-radius: 50%;
  /* 圆形按钮 */
  padding: 0px 5px;
  /* 填充 */
  cursor: pointer;
  /* 鼠标光标变为指针 */
  z-index: 999;
  /* 确保按钮在最上层 */
  box-shadow: 0 2px 12px 0 rgba(7, 4, 4, 0.745)
}

.float-button:hover {
  background-color: #0056b3;
  /* 鼠标悬浮时的背景颜色 */
}
</style>
