<template>
  <div style="margin: 20px;">
    <div class="block">
      <div class="block-title">
        企业法律风险诊断
      </div>
      <div class="block-content">
        <div class="block-sub-title">背景</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;随着社会经济的发展，企业在运营过程中可能遇到各种法律问题。如不能提前进行系统的
          评估和分析，识别潜在的法律风险并提出相应的预防或应对措施，企业则可能会面临违规的风险。</div>
        <div class="block-sub-title">目标</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;帮助企业发现潜在的法律风险点，并通过制定有效的风险管理策略，减少甚至避免法律风 险带来的负面影响。助于维护企业的正常运营和发展。</div>
        <div class="block-sub-title">工具</div>
        <div>
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">《企业法律风险现场诊断工作流程安排及要求》</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">《合规管理组织架构建议》</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">《公司章程的合规审查要点》</el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">《企业税务合规管理体系有效性评估》</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">《辅导方案与实施改善计划如何设计》</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">《合规建设推进进度表》</el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">《公司治理模块风险排查清单》</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">《企业合规管理体系有效性评估》</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">《合同管理证据能力》</el-col>
          </el-row>
        </div>
      </div>
    </div>

    <div class="block">
      <div class="block-title">
        人力资源法律风险防控辅导
      </div>
      <div class="block-content">
        <div class="block-sub-title">背景</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;随着劳动法律法规经常更新和完善、司法实践的变化，这些都影响企业的用工政策和管理
          方式。同时随着劳动者法律意识的提高，劳动争议的数量也在增加。企业如果不妥善处理，可能会面临更 多的仲裁和诉讼。</div>
        <div class="block-sub-title">目标</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;帮助企业在复杂多变的法律环境下，能够更好地理解和遵守相关的法律法规，预防和减少 可能出现的法律风险，保障企业的合法权益。</div>
        <div class="block-sub-title">工具</div>
        <div>
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">《劳动用工合规初步调研相关材料提交清单》</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">《企业人力资源现状调查表》</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">《劳动用工合规指引》</el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">《恶意挖人的应对措施》</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">《劳动用工专项法律顾问服务内部团队指导清单》</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">《劳动用工合规风险清单》</el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">《员工手册参考》</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">《调岗降薪的操作技巧》</el-col>
          </el-row>
        </div>
      </div>
    </div>

    <div class="block">
      <div class="block-title">
        合同（账款）法律风险防控辅导
      </div>
      <div class="block-content">
        <div class="block-sub-title">背景</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;随着经济形势的变化可能导致合同执行中的不确定性增加，如市场波动、通货膨胀等都会
          影响到合同的履行和账款的回收。同时未决条款或其他模糊不清的条款也可能会导致后续纠纷。企业需要
          建立健全的合同管理体系和合规文化，以降低法律风险。</div>
        <div class="block-sub-title">目标</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;帮助企业识别和预防合同账款方面可能遇到的法律风险，确保合同的有效执行和账款的安全回收。</div>
        <div class="block-sub-title">工具</div>
        <div>
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">《营销合同合规管理提供相关材料清单》</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">《合同管理制度建设要点》</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">《企业应收账款的合规风险》</el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">《企业已发生账款的梳理与催收步骤》</el-col>
          </el-row>
        </div>
      </div>
    </div>

    <div class="block">
      <div class="block-title">
        企业家法律风险防控
      </div>
      <div class="block-content">
        <div class="block-sub-title">背景</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在经营企业的过程中企业家会面临各种各样的法律风险，这些风险可能来自内部管理不善 或是外部环境的变化。有效的法律风险防控对于企业长期稳定发展至关重要。
        </div>
        <div class="block-sub-title">目标</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企业家可以更好地识别潜在的法律风险，并采取适当的预防措施，从而减少因法律问题给 企业带来的负面影响。</div>
        <div class="block-sub-title">工具</div>
        <div>
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">《防控及流程再造的实施技巧》</el-col>
          </el-row>
        </div>
      </div>
    </div>

    <div class="block">
      <div class="block-title">
        企业法律风险防控人才培养
      </div>
      <div class="block-content">
        <div class="block-sub-title">背景</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为了适应快速变化的法律环境和市场需求，培养专业的法律风险防控人才已经成为企业可 持续发展的重要组成部分。</div>
        <div class="block-sub-title">目标</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;专业人才不仅能帮助企业识别潜在的法律风险，还能协助制定有效的应对策略，从而保障 企业的合法权益。</div>
        <div class="block-sub-title">工具</div>
        <div>
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8" >【劳动法与劳动合同法】</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">【知识产权法】</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">【安全生产法】</el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">【数据保护与网络安全】</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">【反腐败与商业道德准则】</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">【环境保护法】</el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">【公司治理与合规管理】</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">【商业秘密保护与竞业禁止】</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">【合同法】</el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">【特种设备管理规定】</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">【消费者权益保护法】</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">【民法典相关知识】</el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">【税务法】</el-col>
          </el-row>
        </div>
      </div>
    </div>

    <div class="block">
      <div class="block-title">
        日常法律顾问服务
      </div>
      <div class="block-content">
        <div class="block-sub-title">背景</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企业在日常运营中会面临各种各样的问题，比如：市场交易、内部管理、战略决策，及时获 得专业的法律建议，才能避免因法律疏忽而导致的高额赔偿和罚款。</div>
        <div class="block-sub-title">目标</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;日常法律顾问服务对企业来说是非常重要的，它有助于企业在日常运营中规避法律风险，并 确保其经营活动符合法律规定。</div>
        <div class="block-sub-title">工具</div>
        <div>
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">【日常法务问题咨询】</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">【税务风险管理】</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">【运营监控风险】</el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">【应诉策略辅导】</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">【现金流风险管理】</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">【老板财产/投资咨询】</el-col>
          </el-row>
        </div>
      </div>
    </div>

    <div class="block">
      <div class="block-title">
        进阶模块项目
      </div>
      <div class="block-content">
        <div class="block-sub-title">背景</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司经营中除了涉及日常法务问题外，还涉及知识产权、专利保护、安全等多个层面的活动，企业需要综合考虑内外部环境的变化，采取有效措施保护公司的权益。
        </div>
        <div class="block-sub-title">目标</div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;保护企业知识产权、专利，确保公司竞争的优势。确保公司安全生产，防止各项生产意外。</div>
        <div class="block-sub-title">工具</div>
        <div>
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">《知识产权-企业知识产权及商业秘密合规管理调研相关材料提交清单》</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">《专利权保护-商业秘密保护体系建设要点》</el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">《安全生产-企业安全生产合规体系调研相关材料提交清单》</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">《安全生产-安全生产标准化制度的审查要点》</el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">《安全生产-安全隐患风险排查清单的制作要点》</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">《安全生产-合规义务清单》</el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">《安全生产-安全生产目标及现场安全管理考核标准》</el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">《安全生产-安全生产岗位职责设置技巧》</el-col>
          </el-row>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "Home",
  data() {
    return {

    };
  },
  created() {
  },
  methods: {

  }
};
</script>
<style>
.block {
  margin-top: 50px;
}

.block-title {
  background-color: aliceblue;
  /* height: 40px; */
  line-height: 40px;
  font-size: 18px;
  font-weight: bold;
  padding-left: 10px;
}

.block-content {
  padding-left: 10px;
  line-height: 25px;
}

.block-sub-title {
  /* height: 40px; */
  line-height: 30px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}
</style>