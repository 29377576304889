<template>
  <div style="margin: 20px;">
    <div style="text-align: center;font-size: 32px; font-weight: bold;margin-top: 40px;">损失案例分享</div>
    <div style="text-align: center;">Classic Case</div>

    <div>
      <el-card class="box-card">
        <div slot="header" class="block-title">
          <span>应收账款证据链不足，直接经济损失140万</span>
        </div>
        <div>
          某订制家具公司2021年给九寨沟某镇酒店签订订制家具合同，过程中送货签收
          不规范、所有资料对单价总价无明确约定、结束后没有办理结算，家具公司通过
          多次催要货款无果，2023年底走上诉讼道路。
          由于各项证据不足且无法确认具体数量和价款，最终败诉。
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="block-title">
          <span>倒签合同问题，直接经济损失19万</span>
        </div>
        <div>
          某公司2022年与某国企合作，供货19万；国企采用先供货后走招标流程签订合同形式。 后国企换领导，某公司多次催要货款无果，走上仲裁的道路。某公司提供的送货单据在
          合同签订的时间前没有按照签订的合同条款办理结算、签订合同的主体与送货的主体不 一致、与对方沟通的所有过程没有明确记录对方的身份信息等，最后败诉。
          事实是基础、规范证据是保障。走上法律的道路，谁主张谁举证。我们不能用一句“事 实摆在那里，我就是送货来了，你得支持我”来面对法院的判决。
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="block-title">
          <span>员工纠纷问题，错的这么离谱，还无法无偿开除？</span>
        </div>
        <div>
          某印刷厂，厂里有两名工人多次打架，影响其他工人干活，也影响作业的安全性。 老板想要无偿开除员工。员工还理直气状： 公司哪条规定不能打架？谁提前告诉我了打架要开除？
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="block-title">
          <span>不签合同 ，用第三人公司买保险，我到底哪里错了？</span>
        </div>
        <div>
          某公司办理幼儿园，由于资质不足，未与老师签订合同； 用第三人公司为老师购买社保。幼儿园经营不善关门，老师工作不满一年。 提起劳动仲裁： 用人单位未签订劳动合同，支付两倍工资。
        </div>
      </el-card>
    </div>

  </div>
</template>

<script>

export default {
  name: "Home",
  data() {
    return {

    };
  },
  created() {
  },
  methods: {

  }
};
</script>
<style>

.box-card {
  margin-top: 30px;
}
</style>