import Vue from 'vue'
import App from './App.vue'
import '../public-path'
import Element from 'element-ui'
import './assets/styles/element-variables.scss'
import './assets/styles/index.scss' // global css
import './assets/styles/ruoyi.scss' // ruoyi css
// import './assets/icons' // icon

import 'element-ui/lib/theme-chalk/display.css';

// 分页组件
// import Pagination from "./components/Pagination";
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree, getAge } from "@/utils/ruoyi";
import { download } from '@/utils/request'
import modal from '@/utils/modal'

Vue.use(Element, {
  size: 'medium' // set element-ui default size
})

// Vue.component('Pagination', Pagination)

Vue.config.productionTip = false

// 全局方法挂载
Vue.prototype.parseTime = parseTime
Vue.prototype.download = download
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.$modal = modal

// new Vue({
//   render: h => h(App),
// }).$mount('#app')

// let router = null
let instance = null
function render (props = {}) {
  const { container } = props
  // router = new VueRouter({
  //   routes
  // })
  // if (token && typeof (token) === 'string') {
  //   store.commit('SET_TOKEN', token)
  // }
  instance = new Vue({
    // router,
    // store,
    render: h => h(App)
  }).$mount(container ? container.querySelector('#app') : '#app')
}

// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
  render()
}
export async function bootstrap () {
}
export async function mount (props) {
  console.log('micor app mount')
  render(props)
}

let isUnmounted = false;
export async function unmount () {
  if (isUnmounted) return; // 确保不会重复执行
    isUnmounted = true;

  console.log('micor app unmount')
  instance.$destroy()
  instance.$el.innerHTML = ''
  instance = null
}
