<template>
  <div style="margin-top: 120px;">

    <el-row>
      <el-col class="hidden-md-and-down" :span="5" :offset="5">
        <el-image style="width: 60%; vertical-align: middle; text-align: center;" :src="require('@/assets/person.png')">
        </el-image>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <div class="block-title">团队式服务</div>
        <div style="margin-left: 10px;margin-right: 10px;">根据服务公司所在行业，匹配团队对应专业法律顾问</div>
        <div class="block-title">团队拥有</div>
        <div style="margin-left: 10px;margin-right: 10px;">
          <div>有超10年上市公司高管经验的法律服务顾问</div>
          <div>有培训行业深耕的高级讲师</div>
          <div>有经过部队专业训练的团级干部</div>
          <div>有近10年税务局工作经验的服务顾问</div>
          <div>有10余年审计经验的从业人员</div>
          <div>有专攻知识产权方向的专业顾问</div>
          <div>有计算机专业硕士研究生类别服务顾问</div>
        </div>
      </el-col>
    </el-row>


  </div>
</template>

<script>

export default {
  name: "Home",
  data() {
    return {

    };
  },
  created() {
  },
  methods: {

  }
};
</script>
<style  lang="scss" scoped>
</style>