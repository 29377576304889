<template>
  <div style="margin: 20px;">
    <div>
      <div style="text-align: center;font-size: 32px; font-weight: bold;margin-top: 40px;">服务领域</div>
      <div style="text-align: center;">Development Path</div>

      <el-row :gutter="100" style="margin-top: 50px;">
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <div class="block-title">企业法律风险诊断</div>
          <div class="block-content">帮助企业发现潜在的法律风险点，并通过制定有效的风险管理策略，减少甚至避免法律风险带来的负面影响。</div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <div class="block-title">合同(账款)法律风险防控辅导</div>
          <div class="block-content">帮助企业识别和预防合同账款方面可能遇到的法律风险，确保合同的有效执行和账款的安全回收。</div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <div class="block-title">企业法律风险防控人才培养</div>
          <div class="block-content">人才不仅能帮助企业识别潜在的法律风险，还能协助制定有效的应对策略，从而保障企业的合法权益。</div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <div class="block-title">进阶模块项目</div>
          <div class="block-content">企业知识产权、专利，确保公司竞争的优势。确保公司安全生产，防止各项生产意外。</div>
        </el-col>
      </el-row>
      <el-row :gutter="100" style="margin-top: 50px;">
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <div class="block-title">人力资源法律风险防控辅导</div>
          <div class="block-content">预防和减少可能出现的法律风险，保障企业的合法权益。</div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <div class="block-title">企业家法律风险防控</div>
          <div class="block-content">企业家可以更好地识别潜在的法律风险，并采取适当的预防措施，从而减预防和减少因法律问题给企业带来的负面影响。</div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <div class="block-title">日常法律顾问服务</div>
          <div class="block-content">有助于企业在日常运营中规避法律风险，并确保其经营活动符合法律规定。 </div>
        </el-col>
      </el-row>
    </div>

    <div style="margin-top:100px">
      <div style="text-align: center;font-size: 32px; font-weight: bold;margin-top: 40px;">新式法律顾问介绍</div>
      <div style="text-align: center;">Introduce</div>

      <div>
        <div class="block-title">哪些模块能够带来收益</div>
        <div style="margin-top: 10px;">
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="a" label="项目" width="180">
            </el-table-column>
            <el-table-column prop="b" label="合同" width="180">
            </el-table-column>
            <el-table-column prop="c" label="账款">
            </el-table-column>
            <el-table-column prop="d" label="劳动关系">
            </el-table-column>
            <el-table-column prop="e" label="经营风险防控">
            </el-table-column>
            <el-table-column prop="f" label="成本管理">
            </el-table-column>
            <el-table-column prop="g" label="产权保护">
            </el-table-column>
            <el-table-column prop="h" label="争议解决">
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div style="margin-top: 50px;">
        <div class="block-title">新式法律顾问项目服务流程</div>
        <div style="margin-top: 10px;">
          <el-row :gutter="50" style="margin-top: 20px;">
            <el-col :span="3" offset="2" class="l-content">书面调研</el-col>
            <el-col :span="1" class="arrow-img">
              <el-image :src="require('@/assets/arrow-left.png')"></el-image>
            </el-col>
            <el-col :span="3" class="l-content">专项合规实 施指引</el-col>
            <el-col :span="1" class="arrow-img">
              <el-image :src="require('@/assets/arrow-left.png')"></el-image>
            </el-col>
            <el-col :span="3" class="l-content">风险防控与 流程再造</el-col>
            <el-col :span="1" class="arrow-img">
              <el-image :src="require('@/assets/arrow-left.png')"></el-image>
            </el-col>
            <el-col :span="3" class="l-content">现场调研</el-col>
            <el-col :span="1" class="arrow-img">
              <el-image :src="require('@/assets/arrow-left.png')"></el-image>
            </el-col>
            <el-col :span="3" class="l-content">起草诊断报告</el-col>
          </el-row>
          <el-row>
            <el-col :span="1" offset="19" class="arrow-img">
              <el-image :src="require('@/assets/arrow-left.png')" style="transform: rotate(90deg);top: 15px;">
              </el-image>
            </el-col>
          </el-row>

          <el-row :gutter="50" style="margin-top: 20px;">
            <el-col :span="3" offset="2" class="l-content">提供超值 服务</el-col>
            <el-col :span="1" class="arrow-img">
              <el-image :src="require('@/assets/arrow-left.png')"></el-image>
            </el-col>
            <el-col :span="3" class="l-content">文件、合同 起草、修订</el-col>
            <el-col :span="1" class="arrow-img">
              <el-image :src="require('@/assets/arrow-left.png')"></el-image>
            </el-col>
            <el-col :span="3" class="l-content">辅导方案与 实施计划</el-col>
            <el-col :span="1" class="arrow-img">
              <el-image :src="require('@/assets/arrow-left.png')"></el-image>
            </el-col>
            <el-col :span="3" class="l-content">解读诊断报告</el-col>
            <el-col :span="1" class="arrow-img">
              <el-image :src="require('@/assets/arrow-left.png')"></el-image>
            </el-col>
            <el-col :span="3" class="l-content">日常法律咨询 与解答</el-col>
          </el-row>
          <el-row>
            <el-col :span="1" offset="3" class="arrow-img">
              <el-image :src="require('@/assets/arrow-left.png')" style="transform: rotate(90deg);top: 15px;left:-25px">
              </el-image>
            </el-col>
          </el-row>
          <el-row :gutter="50" style="margin-top: 20px;">
            <el-col :span="3" offset="2" class="l-content">法律顾问服务 质量保障</el-col>
          </el-row>
        </div>
      </div>

      <div style="margin-top: 50px;">
        <div class="block-title">新式法律顾问项目特色</div>
        <div style="margin-top: 10px;">
          <el-row>
            <el-col :span="4" class="t-content" style="background-color:darkkhaki;">诊断标准化</el-col>
            <el-col :span="4" class="t-content" style="background-color:darkcyan;">分析数量化</el-col>
            <el-col :span="4" class="t-content" style="background-color:darkblue;">辅导工具化</el-col>
            <el-col :span="4" class="t-content" style="background-color:darkgreen;">防范体系化</el-col>
            <el-col :span="4" class="t-content" style="background-color:darkmagenta;">文件证据化</el-col>
            <el-col :span="4" class="t-content" style="background-color:darkorange;">执行简单化</el-col>
          </el-row>
          <div style="padding-left: 10px;">全面展示90%风险，防住60%常态化风险，控制20%可控风险，监控10%不可控风险</div>
        </div>

        <div style="margin-top: 10px;padding-left: 10px;">
          <div>
            <el-image :src="require('@/assets/double-arrow.png')" class="double-arrow" />诊断标准化: 按照诊断流程、排查清单进行逐一排查风险。
          </div>
          <div>
            <el-image :src="require('@/assets/double-arrow.png')" class="double-arrow" />分析数量化: 评估风险点采用损失程度、发生概率的数据。
          </div>
          <div>
            <el-image :src="require('@/assets/double-arrow.png')" class="double-arrow" />辅导工具化: 提供一整套完整的风险防范工具、表单、文件。
          </div>
          <div>
            <el-image :src="require('@/assets/double-arrow.png')" class="double-arrow" />防范体系化: 从招聘、入职、在职、离职的流程系统防范。
          </div>
          <div>
            <el-image :src="require('@/assets/double-arrow.png')" class="double-arrow" />文件证据化: 按照诉讼的要求整理文件，使其具备证据力。
          </div>
          <div>
            <el-image :src="require('@/assets/double-arrow.png')" class="double-arrow" />执行简单化: 推行相关措施不增加工作量，简单易行。
          </div>
        </div>
      </div>

    </div>
    <!-- <div style="margin-top: 100px;">

      <el-row>
        <el-col :span="6" :offset="6">
          <div class="ellipse">系统性服务</div>
        </el-col>
        <el-col :span="6">
          <div class="ellipse">定制化服务</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" :offset="3" style="margin-top: 56px;">
          <div class="ellipse">主动式服务</div>
        </el-col>
        <el-col :span="8">
          <div class="ellipse-big">
            我们存在的价值！<br/>
            帮你省钱、赚钱 携手同行 共同成长
            </div>
        </el-col>
        <el-col :span="4" style="margin-top: 56px;">
          <div class="ellipse">诉讼前预案</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" :offset="5">
          <div class="ellipse">促成你的交易</div>
        </el-col>
        <el-col :span="4">
          <div class="ellipse">责任、目标效益</div>
        </el-col>
        <el-col :span="4">
          <div class="ellipse">用心的服务</div>
        </el-col>
      </el-row>

    </div> -->
  </div>
</template>

<script>

export default {
  name: "Home",
  data() {
    return {
      tableData: [
        { a: "服务内容", b: "标准化模板 风险评估与管理 合同执行优化", c: "账龄分析 应收账款的管理与催收 应付账款的延期与应对 现金优化", d: "规章制度建设 升降调薪技巧 解除劳动关系", e: "降低运营成本 促进合规监管 优化资源配置", f: "目标成本法 标准成本法 作业成本法", g: "资产评估 防止侵权 促进技术转让 与合作", h: "劳动关系争议 合同争议 产权争议 税务争议" },
        { a: "风险防控", b: "避免合同纠纷产 品经济损失", c: "避免债权债务集中爆发", d: "避免劳务纠纷、 经济赔偿", e: "预防经济损失", f: "降低成本", g: "保障产权经济效益", h: "减少企业损失" }
      ]
    };
  },
  created() {
  },
  methods: {

  }
};
</script>
<style>
.block-title {
  background-color: aliceblue;
  /* height: 40px; */
  line-height: 40px;
  font-weight: bold;
  padding-left: 10px;
}

.block-content {
  padding-left: 10px;
  padding-right: 10px;
  line-height: 25px;
}


.ellipse {
  width: 200px;
  /* 椭圆形的宽度 */
  height: 100px;
  /* 椭圆形的高度，这将形成一个椭圆 */
  background-color: #673AB7;
  /* 椭圆形的填充颜色 */
  border-radius: 50%;
  /* 圆角的百分比，50%将创建圆形 */

  display: flex;
  align-items: center;
  justify-content: center;
}

.ellipse-big {
  width: 400px;
  /* 椭圆形的宽度 */
  height: 200px;
  /* 椭圆形的高度，这将形成一个椭圆 */
  background-color: #673AB7;
  /* 椭圆形的填充颜色 */
  border-radius: 50%;
  /* 圆角的百分比，50%将创建圆形 */

  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-img {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.arrow-img .el-image {
  top: 45px
}

.double-arrow {
  width: 24px;
  top: 15px;
  margin-right: 10px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* height: 100vh; */
}

.l-content {
  min-height: 100px;
  background-color: rgb(210, 223, 234);
  display: flex;
  align-items: center;
  justify-content: center;
}

.t-content {
  min-height: 50px;
  color: white;
  border-radius: 5px;
  /* text-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>