<template>
  <div>
    <el-carousel height="300px" :autoplay="false">
      <el-carousel-item>
        <el-image
          style="display: block; width: 100%; height: 300px; vertical-align: middle; text-align: center;filter: brightness(80%); opacity: 0.2;"
          :src="require('@/assets/bg.png')" fit="cover">
        </el-image>
        <div>
          <div class="vertical-title hidden-md-and-down" style="left: 40%;">法律服务</div>
          <div style="position: absolute;top: 40px;left: 45%;font-size: 24px;color: #a79340;line-height: 50px;">
            <div>用心结合实战</div>
            <div>专业服务的我们</div>
            <div>AI无法取代!</div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>

    <div>
      <div style="    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin: 20px;">服务内容</div>
      <div style="margin-left:80px;margin-right:80px;margin-bottom: 50px;">
        <el-collapse style="width: 100%;">
          <el-collapse-item title="1、法律咨询和法律意见" name="1">
            <div>法律顾问会提供有关生产、经营和管理的法律事务咨询，并出具咨询意见书。他们还会解释相关的法律规定，包括中国（不含港澳台）的法律、法规、规章、司法解释等。</div>
          </el-collapse-item>
          <el-collapse-item title="2、合同审查和管理" name="2">
            <div>法律顾问会草拟、审查、修改公司章程、股东协议、合作协议、劳动合同、交易合同等各类合同和协议类法律文书。他们还会协助建立法律文件归档和合同管理制度，确保合同的有效性和合规性。</div>
          </el-collapse-item>
          <el-collapse-item title="3、内部规章制度制定" name="3">
            <div>法律顾问会制定和完善聘请单位的内部规章制度，确保这些制度符合法律法规的要求，并能在日常经营活动中得到有效执行。</div>
          </el-collapse-item>
          <el-collapse-item title="4、纠纷处理" name="4">
            <div>就处理劳资纠纷和外部法律纠纷，法律顾问会提供法律分析和处理意见，并出具案件分析意见书。在必要时，他们还会代理诉讼、仲裁、劳动仲裁、行政复议、听证等活动。</div>
          </el-collapse-item>
          <el-collapse-item title="5、法律培训和宣传" name="5">
            <div>法律顾问会进行法律知识专题培训，以提高员工的法律意识。他们还会不定期向顾问单位介绍宣传国家和地方新颁布的与企业关系密切的法律法规。</div>
          </el-collapse-item>
          <el-collapse-item title="6、法律文件签发" name="6">
            <div>法律顾问会根据顾问单位的需要，以法律顾问的名义对外签发律师函，以维护企业的合法权益。</div>
          </el-collapse-item>
          <el-collapse-item title="7、重大法律事务支持" name="7">
            <div>法律顾问会就重大法律事务出具法律意见书，并应邀参加聘请单位的重大会议，提供法律意见。</div>
          </el-collapse-item>
          <el-collapse-item title="8、法律风险评估与预防" name="8">
            <div>法律顾问会对客户可能发生的法律风险进行预测与防范，提出法律意见与对策，以帮助企业预防法律纠纷。</div>
          </el-collapse-item>
          <el-collapse-item title="9、其他法律服务" name="9">
            <div>根据顾问单位的具体需求，法律顾问还可能提供其他相关的法律服务，如代理企业进行资信调查、出具律师见证书、办理商标、专利的申请、注册、转让等业务。</div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Home",
  data() {
    return {

    };
  },
  created() {
  },
  methods: {

  }
};
</script>

<style>
.vertical-text {
  writing-mode: vertical-rl;
  /* 文字从上到下竖排，从右到左 */
  transform: rotate(0deg);
  /* 修正文字方向，确保从上到下阅读 */
}

.vertical-title {
  /* 文字从上到下竖排，从右到左 */
  writing-mode: vertical-rl;
  /* 修正文字方向，确保从上到下阅读 */
  transform: rotate(0deg);
  position: absolute;
  top: 50px;
  left: 50px;
  font-size: 32px;
  font-weight: bold;
}

.sub-title {
  font-weight: bold;
  line-height: 30px;
}

.arrow-dotted-up {
  width: 11px;
  height: 11px;
  display: inline-block;
  position: relative;
  /* 旋转180度 */
  /* transform: rotate(180deg) translateY(-250%); */
}

.arrow-dotted-up::before {
  content: '';
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 0 solid transparent;
  border-bottom: 10px solid #034d9a;
}

.arrow-dotted-up .line {
  box-sizing: border-box;
  position: absolute;
  width: 50%;
  height: 30px;
  top: 10px;
  border-right: 1px dashed #034d9a;
}

.arrow-dotted-down {
  width: 10px;
  height: 10px;
  display: inline-block;
  position: relative;
  top: 28px;
}

.arrow-dotted-down::before {
  content: '';
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #034d9a;
  border-bottom: 0 solid transparent;
}

.arrow-dotted-down .line {
  box-sizing: border-box;
  position: absolute;
  width: 50%;
  height: 30px;
  top: -30px;
  border-right: 1px dashed #034d9a;
}

.arrow-box {
  border: solid transparent;
  position: relative;
  padding: 10px;
  margin: 20px;
  background-color: #FFFFFF;
  border-width: 10px;
}

.arrow-box:after {
  content: "";
  position: absolute;
  left: 20px;
  bottom: -10px;
  /* 是边框宽度的一半 */
  border: solid;
  border-color: #4b4343 transparent transparent transparent;
  border-width: 10px;
}

.dashed-border {
  position: relative;
  border: 2px dashed #000;
  /* 虚线边框 */
  padding: 10px;
  margin: 20px;
  background-color: #f9f9f9;
}

.dashed-border::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  bottom: -10px;
  /* 箭头位置 */
  left: 50%;
  /* 箭头位置 */
  border-width: 10px 10px 0;
  /* 上边框宽度 */
  border-style: dashed solid dashed;
  /* 边框样式 */
  border-color: #000 transparent transparent;
  /* 边框颜色 */
  transform: translateX(-50%);
  /* 位置调整 */
}
</style>
